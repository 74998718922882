import React from "react";
import { Popover, Text, Box } from '@mantine/core';
import { useCallback } from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from "react-redux";
import { useState, useRef, useEffect } from "react";
import { Send,Database } from 'tabler-icons-react';
import { Tabs } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';

import { TextInput } from '@mantine/core';

import { useDisclosure } from '@mantine/hooks';
import {Link,useNavigate,useLocation} from 'react-router-dom';

import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { RichTextEditor, useRichTextEditorContext } from '@mantine/tiptap';

import { ActionIcon,Paper, Grid, Textarea, Modal, Button, Group } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

function InsertStarControl() {
  const { editor } = useRichTextEditorContext();
  return (
    <RichTextEditor.Control
      onClick={() => editor?.commands.insertContent('⭐')}
      aria-label="Insert star emoji"
      title="Insert star emoji"
    >
      <Send size="1rem" />
    </RichTextEditor.Control>
  );
}

function Switcher({model,setModel,value,tip}) {
  const [opened, { close, open }] = useDisclosure(false);

  return (
    <Popover width={200} position="bottom" withArrow shadow="md" opened={opened}>
      <Popover.Target>
            <Button variant={value == model ? "light" : "subtle"}
              className="settings-button"
              onMouseEnter={open}
              onMouseLeave={close}
              size="xs"
              compact
              onClick={()=>{
                setModel(value)
              }}>
              <span>{value.toUpperCase()}</span>
            </Button>
      </Popover.Target>
      <Popover.Dropdown sx={{ pointerEvents: 'none' }}>
        <Text size="sm">{tip}</Text>
      </Popover.Dropdown>
    </Popover>
  );
}

function TokenCost({counter}) {
  const [opened, { close, open }] = useDisclosure(false);

  return (
    <Popover width={200} position="bottom" withArrow shadow="md" opened={opened}>
      <Popover.Target>
            <Button variant="subtle"
              className="settings-button"
              onMouseEnter={open}
              onMouseLeave={close}
              size="xs"
              compact
              onClick={()=>{}}>
              <span>Context Token:{counter}</span>
            </Button>
      </Popover.Target>
      <Popover.Dropdown sx={{ pointerEvents: 'none' }}>
        <Text size="sm">Token 是计算 ChatGPT 工作量的单位。当向 ChatGPT 发送信息时，为了让 ChatGPT 了解信息的上下文，将一起发送会话历史，您可以点击重置会话来清空历史记录。</Text>
      </Popover.Dropdown>
    </Popover>
  );
}

const RichTextEditorWrapper = styled.div`
    .mantine-RichTextEditor-content{
      font-size:1.1rem;
      background:#111;
    }
`;

const ListItem = styled.div`
    display: block;
    position: relative;
    cursor: pointer;
    padding: 0.4rem 1rem;
    margin: 0.218rem 0;
    line-height: 1.7;
    text-decoration: none;
    border-radius: 0.25rem;
    &:hover, &:focus, &:active {
        background: rgba(0, 0, 0, 0.9);
    }
    &.selected {
        background: #2b3d54;
    }
    strong {
        display: block;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.6;
        padding-right: 1rem;
        color: white;
    }
    p {
        font-size: 0.8rem;
        font-weight: 200;
        opacity: 0.8;
    }
`;

const Container = styled.div`
    background: #192839;
    background: rgba(25, 40, 57, 0.98);
    border-top: thin solid rgba(0, 0, 0, 0.2);
    position: fixed;
    bottom: 0rem;
    height: 8rem;
    left: 0;
    right: 0;
    padding-left: 1rem;
    padding-right: 1rem;

    .inner {
        max-width: 50rem;
        margin:auto;
        text-align: left;
    }

    .actions {
        text-align: left;
        margin-top:0.5rem;
        margin-bottom:0.5rem;
    }

    .settings-button {
        margin: 0rem 0rem 0rem 0rem;
        font-size: 0.7rem;
        color: #999;
    }
`;

function HeaderButton(props) {
    return (
        <Button size='xs'
                ml={props.ml?props.ml:0}
                variant={props.variant || 'subtle'}
                onClick={props.onClick}>
            {props.children && <span>
                {props.children}
            </span>}
        </Button>
    )
}

const Example = function(){
  const { editor } = useRichTextEditorContext();
  useEffect(()=>{
    editor?.getText() == "" && editor.commands.insertContent("hihi hi hi === \n\n ====\n\n")
  },[editor])
  return null
}

const PromptBox = function({id,chatLog,setChatLog,closeChat,handleSubmit}){
  const [inputPrompt, setInputPrompt] = useState("");
  const [counter, setCounter] = useState(0);
  const user = useSelector((state)=>state.user)
  const [showLib, setLib] = useState(true);
  const navigate = useNavigate();
  const { height, width } = useViewportSize();
  const [model, setModel] = useState("gpt-4o-mini");

  const [Prompts,setPrompts] = useState({
    user:[],
    code:[],
    default:[],
    language:[],
  });

  useEffect(()=>{
    /*
    if(user.points_available > 10000){
      setModel("gpt-4")
    }
    */
  },[user])

  useEffect(()=>{

    const fetchPrompts = async () => {
        const response = await fetch("/api/prompts", {
          method: "GET",
          headers: { "Content-Type": "application/json" }
        });

        const data = await response.json();
        setPrompts({
          user: data.data.prompts,
          code: data.data.system.code,
          default: data.data.system.default,
          language: data.data.system.language,
        })
    }

    fetchPrompts()

  },[])

  function PaperPlaneSubmitButton(props) {
    return (
      <ActionIcon size="sm"
      loading={props.disabled}
      onClick={props.onSubmit}>
        <Send/>
      </ActionIcon>
    );
  }
  useEffect(()=>{
    let ts = 0;
    chatLog.map((message)=>{
      if(message.usage){
        ts = ts+message.usage.total_tokens
      }else{
        ts = (message.chatPrompt + message.botMessage).length/8>>0
      }
      //ts = ts + (inputPrompt.length/5) >> 0
    })
    setCounter(ts)
  },[chatLog,inputPrompt])

  function showEditor(){
  }

  const [opened, { open, close }] = useDisclosure(false);

  const editor = useEditor({
    extensions: [StarterKit],
    content:`Summarize the text below as a bullet point list of the most important points.<br/>Text: """<br/>{paste your text input here}<br/>""""<br/>`
  });

  return (
    <Container>
      <Modal
        opened={opened}
        onClose={close}
        title="Advanced Prompt Editor"
        fullScreen
        transitionProps={{ transition: 'fade', duration: 200 }}
      >
     <Grid>
      {showLib?
      <Grid.Col span={4}>
         <Paper shadow="xs" p="md" withBorder>
            <Tabs color="teal" defaultValue={Prompts.user.length == 0 ? "language":"my"}>
              <Tabs.List>
                <Tabs.Tab value="language">翻译和语言</Tabs.Tab>
                <Tabs.Tab value="code">程序</Tabs.Tab>
                <Tabs.Tab value="default">经典</Tabs.Tab>
                <Tabs.Tab value="my" color="blue">
                  我的
                </Tabs.Tab>
              </Tabs.List>

              <Tabs.Panel value="my" pt="xs">
                {Prompts.user.length == 0 && <ListItem><strong>你还没有自定义的 <Link to="/console/prompts">Prompts</Link></strong></ListItem>}
                {Prompts.user.map((item)=>{
                  return <ListItem onClick={()=>{
                    editor.commands.clearContent()
                    editor.commands.insertContent(item.content)
                  }}><strong>{item.title}</strong></ListItem>
                })}
              </Tabs.Panel>

              <Tabs.Panel value="code" pt="xs">
                {Prompts.code.map((item)=>{
                  return <ListItem onClick={()=>{
                    editor.commands.clearContent()
                    editor.commands.insertContent(item.content)
                  }}><strong>{item.title}</strong></ListItem>
                })}
              </Tabs.Panel>

              <Tabs.Panel value="language" pt="xs">
                {Prompts.language.map((item)=>{
                  return <ListItem onClick={()=>{
                    editor.commands.clearContent()
                    editor.commands.insertContent(item.content)
                  }}><strong>{item.title}</strong></ListItem>
                })}
              </Tabs.Panel>

              <Tabs.Panel value="default" pt="xs">
                {Prompts.default.map((item)=>{
                  return <ListItem onClick={()=>{
                    editor.commands.clearContent()
                    editor.commands.insertContent(item.content)
                  }}><strong>{item.title}</strong></ListItem>
                })}
              </Tabs.Panel>

            </Tabs>
         </Paper>
      </Grid.Col>:null}
      <Grid.Col span={"auto"}>
      <RichTextEditorWrapper>
    <RichTextEditor editor={editor}>
      <RichTextEditor.Toolbar>
          <HeaderButton icon="plus" onClick={()=>{
              let message = editor.getText({ blockSeparator: "\n" }).split("\n").filter((item)=>{return item[0] != "#"}).join("\n")
              handleSubmit(message,model)
              setInputPrompt("");
              close()
          }}  variant="light">
              发送
          </HeaderButton>
      </RichTextEditor.Toolbar>
      <RichTextEditor.Content>
      </RichTextEditor.Content>
    </RichTextEditor>
      </RichTextEditorWrapper>
      </Grid.Col>
    </Grid>
      </Modal>
    <div className="inner">

      <div className="actions">
          <HeaderButton icon="plus" onClick={()=>{
              setChatLog([])
          }}  variant="light">
              Reset Conversation
          </HeaderButton>

          {width> 900 ?
          <HeaderButton  ml={5} variant="light" onClick={open}>
              <span>Advanced Editor</span>
          </HeaderButton>:null}

          <HeaderButton ml={5} icon="plus" onClick={()=>{
              closeChat()
          }}  variant="light">
              Close Tab
          </HeaderButton>

      </div>

          <Textarea
          autosize
          minRows={1}
          maxRows={1}
          variant="unstyled"
          style={{
            background: "rgba(0, 0, 0, 0.4)",
            color:"#fff",
            borderRadius:"0.3rem",
            paddingLeft:"1rem",
            paddingRight:"1rem"
          }}
          placeholder={"Message here..."}
          value={inputPrompt}
          onChange={(e) => setInputPrompt(e.target.value)}
          rightSection={<div style={{
            opacity: '0.8',
              paddingRight: '0.4rem',
          }}>
            <PaperPlaneSubmitButton onSubmit={(e)=>{
              e.preventDefault();
              handleSubmit(inputPrompt,model)
              setInputPrompt("");
            }} />
          </div>}
          onKeyDown={(e)=>{

            if(e.keyCode === 13){
              e.preventDefault();
              handleSubmit(inputPrompt,model)
              setInputPrompt("");
            }

          }} />

          <Switcher tip={"Points 计算方式为 OpenAI 的 Token 计数 * 1"} value={"gpt-4o-mini"} model={model} setModel={setModel} />
          <Switcher tip={"Points 计算方式为 OpenAI 的 Token 计数 * 5"} value={"gpt-4o"} model={model} setModel={setModel} />
          <Switcher tip={"Points 计算方式为 OpenAI 的 Token 计数 * 1"} value={"gpt-3.5-turbo"} model={model} setModel={setModel} />

    </div>
    </Container>
  )
}


export default PromptBox;
